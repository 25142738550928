import Vue from "vue";
import VueRouter from "vue-router";
import {pages} from "@/utils/global-methods";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: pages("home")
  },
  {
    path: "/test",
    name: "test",
    component: pages("test")
  },
  {
    path: "/privacy",
    name: "privacy",
    component: pages("privacy")
  },
  {
    path: "/about",
    name: "about",
    component: pages("about"),
    meta: {
      title: "About"
    }
  },
  {
    path: "/consulting",
    name: "consulting",
    component: pages("consulting")
  },
  {
    path: "/works",
    component: pages("works"),
    meta: {
      title: "Works"
    },
    children: [
      {
        path: "",
        name: "works-list",
        component: pages("works/list")
      },
      {
        path: ":id/",
        name: "works-detail",
        component: pages("works/detail")
      }
    ]
  },
  {
    path: "/careers",
    name: "careers",
    component: pages("careers"),
    meta: {
      title: "Careers"
    }
  },
  {
    path: "/request",
    name: "request",
    component: pages("request"),
    meta: {
      title: "Request",
      hiddenBnr: true
    }
  },
  {
    path: "/magazine",
    component: pages("magazine"),
    meta: {
      title: "Magazine",
      hiddenBnr: true
    },
    children: [
      {
        path: "",
        name: "magazine-list",
        component: pages("magazine/list")
        /*beforeEnter(to, from, next) {
          const params = {};
          window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => {
            params[key] = value;
          });
          if (params.path) {
            next(params.path);
          } else {
            next();
          }
        }*/
      },
      {
        path: ":id",
        name: "magazine-detail",
        component: pages("magazine/detail"),
        beforeEnter(to, from, next) {
          if (isNaN(to.params.id * 1)) {
            next("404");
          } else {
            next();
          }
        },
        children: [
          {
            path: "",
            name: "magazine-item",
            component: pages("magazine/item"),
            meta: {
              hiddenBnr: false
            }
          }
        ]
      },
      {
        path: "*",
        name: "404",
        component: pages("error/404"),
        meta: {
          layout: "Clean"
        }
      }
    ]
  },
  {
    path: "/210125-2",
    redirect: "/magazine/17"
  },
  {
    path: "/201030-2",
    redirect: "/magazine/16"
  },
  {
    path: "/200915-2",
    redirect: "/magazine/15"
  },
  {
    path: "/200831-2",
    redirect: "/magazine/14"
  },
  {
    path: "/200716-2",
    redirect: "/magazine/13"
  },
  {
    path: "/200630-2",
    redirect: "/magazine/12"
  },
  {
    path: "/200221-2",
    redirect: "/magazine/11"
  },
  {
    path: "/191205-2",
    redirect: "/magazine/10"
  },
  {
    path: "/191021-2",
    redirect: "/magazine/9"
  },
  {
    path: "/190930-2",
    redirect: "/magazine/8"
  },
  {
    path: "/190814",
    redirect: "/magazine/7"
  },
  {
    path: "/190718",
    redirect: "/magazine/6"
  },
  {
    path: "/190627-2",
    redirect: "/magazine/5"
  },
  {
    path: "/190509-2",
    redirect: "/magazine/4"
  },
  {
    path: "/웹-사이트를-만들-때-주의해야-할-것들",
    redirect: "/magazine/3"
  },
  {
    path: "/비주얼-디자인으로-브랜드를-정의하다",
    redirect: "/magazine/2"
  },
  {
    path: "/2019에도변하지않을uiux트렌드일곱가지",
    redirect: "/magazine/1"
  },
  {
    path: "*",
    name: "404",
    component: pages("error/404"),
    meta: {
      layout: "Clean"
    }
  }
];
const router = new VueRouter({
  mode: "history",
  base: `${process.env.BASE_URL}`,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if ((to.name === "about" && !(from.name === "works-detail")) || from.name === "careers") return;
    let position = {x: 0, y: 0};
    if (savedPosition) {
      position = savedPosition;
    }
    store.dispatch("AC_SAVED_POSITION", position);

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(position);
      }, 500);
    });
  }
});

export default router;
