import "@babel/polyfill";
import "es6-promise/auto";
import "element-scroll-polyfill";
import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import Fragment from "vue-fragment";
import VueJsModal from "vue-js-modal";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueScrollmagic from "vue-scrollmagic";
import SmoothScrollbar from "vue-smooth-scrollbar";
import VueTextareaAutosize from "vue-textarea-autosize";
import Meta from "vue-meta";
import VueAwesomeSwiper from "vue-awesome-swiper";
import smoothscroll from "smoothscroll-polyfill";
import {Dialog, Scrollbar} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueSocialSharing from "vue-social-sharing";
import PortalVue from "portal-vue";
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: {id: "G-BK1GQ1XDFQ"}
});

smoothscroll.polyfill();

Vue.config.productionTip = false;

Vue.filter("dateFormat", value => {
  if (!value) return "";
  const date = new Date(value);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month >= 10 ? month : "0" + month;
  let day = date.getDate();
  day = day >= 10 ? day : "0" + day;
  return `${year}.${month}.${day}`;
});

Vue.use(VueScrollmagic, {
  vertical: true,
  globalSceneOptions: {},
  loglevel: 2,
  refreshInterval: 100
});
Vue.use(VueSocialSharing);
Vue.use(Scrollbar);
Vue.use(Dialog);
Vue.use(VueTextareaAutosize);
Vue.use(SmoothScrollbar);
Vue.use(PortalVue);
Vue.use(Meta);
Vue.use(PerfectScrollbar, {
  watchOptions: true,
  tag: "section",
  options: {
    scrollingThreshold: 2000,
    minScrollbarLength: 50,
    wheelSpeed: 1,
    swipeEasing: false
  }
});

Vue.use(VueJsModal, {
  dialog: true,
  dynamicDefaults: {
    scrollable: false,
    draggable: false,
    resizable: false,
    focusTrap: true,
    adaptive: true,
    width: "800px",
    height: "auto"
  }
});
Vue.use(Fragment.Plugin);
Vue.use(VueAwesomeSwiper);

/**
 * component 전역 등록
 */
const requireComponent = require.context("@/components/global", true, /[\w-]+\.vue$/);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
