<template>
  <div class="tab-top">
    <div class="tab-list">
      <ul>
        <li
          ref="tabItem"
          v-for="(item, index) in list"
          :key="index"
          :class="{on: active === item.id}"
        >
          <button @click="tabEvent(item.id)">
            <span v-html="item.title" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: "tab",
    model: {
      prop: "active",
      event: "activeChange"
    },
    props: {
      list: Array,
      active: String,
      name: {
        type: String,
        default: "tab"
      }
    },
    created() {
      const id = this.$route.query?.[this.name] || this.active;
      console.log("page created", id);
      this.$emit("activeChange", id);
    },
    methods: {
      tabEvent(id) {
        this.$emit("activeChange", id);
        this.$router.push(
          {
            query: {...this.$route.query, [this.name]: id}
          },
          undefined,
          {
            scroll: false
          }
        );
      }
    }
  };
</script>
<style scoped lang="scss">
  .tab-top {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 1264px;
    height: 24px;
    margin: 60px auto 0;
    padding-top: 60px;
    pointer-events: auto;
    &.flex-end {
      inset: 0px auto auto 0 !important;
      .tab-list {
        width: 100%;
        min-width: 1280px;
        justify-content: flex-end;
        margin-top: 70px;
        ul li {
          button {
            font-size: 20px;
            &:after {
              height: 2px;
            }
          }
          + li {
            margin-left: 30px;
          }
        }
      }
    }
    .tab-list {
      display: flex;
      justify-content: flex-start;
      width: 1264px;
      padding: 0 20px;
      will-change: transform;
      box-sizing: border-box;
      ul {
        justify-self: flex-end;
        display: flex;
        li {
          &.on {
            button {
              color: $red;
              &:after {
                width: 100%;
                background: $red;
              }
            }
          }
          button {
            @include montserrat;
            font-weight: bold;
            font-size: 24px;
            line-height: 1;
            color: #a0a0a0;
            background: none;
            padding: 0;
            &:after {
              display: block;
              content: "";
              margin-top: 4px;
              width: 0;
              height: 3px;
              background: #a0a0a0;
              transition: width 250ms ease-in-out;
              transform-origin: 0 0;
            }
            &:hover:after {
              width: 100%;
            }
          }
          + li {
            margin-left: 50px;
          }
        }
      }
    }
  }
</style>
