<template>
  <div class="modal-overlay" v-if="isOpen">
    <div class="modal-content">
      <button class="close-btn" @click="closeModal">
        <img src="@/assets/icons/ic_close.svg" alt="닫기" />
      </button>

      <!-- Title Slot -->
      <div class="modal-title">
        <slot name="title">기본 제목</slot>
      </div>

      <!-- Content Slot -->
      <div class="modal-body">
        <slot>내용이 여기에 들어갑니다.</slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isOpen: Boolean
    },
    data() {
      return {
        modalTop: 0
      };
    },
    watch: {
      isOpen(newVal) {
        if (newVal) {
          this.setModalPosition();
          window.addEventListener("scroll", this.setModalPosition);
        } else {
          window.removeEventListener("scroll", this.setModalPosition);
        }
      }
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
      setModalPosition() {
        const scrollY = window.scrollY || window.pageYOffset;
        const windowHeight = window.innerHeight;
        const modalHeight = 300; // 예상 모달 높이 (조정 가능)
        this.modalTop = scrollY + (windowHeight - modalHeight) / 2;
      }
    }
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }

  .modal-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 82px 40px;
    box-sizing: border-box;
    width: 1310px;
    border-radius: 8px;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
    }
  }

  .modal-title {
    color: #000;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 700;
    line-height: 145%;
  }

  .modal-body {
    margin-top: 40px;
    overflow-y: scroll;
    max-height: 60vh;
    overscroll-behavior: none;
  }
</style>
